import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import {
  Button, 
} from '@chakra-ui/react';
import { DownloadIcon } from "@chakra-ui/icons";
import { sendCloudwatchEvent } from "../../utils";

const StripeCheckoutButton = (props) => {

  const handleCanvas = async (event) => {
    event.preventDefault();

    const imageUrl = props.imageUrl;
    sendCloudwatchEvent("Canvas checkout button clicked for size " + props.size + " with imageUrl: " + imageUrl);

    let clientReferenceId = imageUrl.split('/')[3];
    clientReferenceId = clientReferenceId.slice(0,36);

    if (props.size === '12') {
      window.location.href = `https://buy.stripe.com/5kA2aOcevcRa4BW9AE?client_reference_id=${clientReferenceId}`;
    }

    else if (props.size === '16') {
      window.location.href = `https://buy.stripe.com/eVaaHk1zReZid8sbIN?client_reference_id=${clientReferenceId}`;
    }

    else if (props.size === '20') {
      window.location.href = `https://buy.stripe.com/fZe3eS6UbdVegkE006?client_reference_id=${clientReferenceId}`;
    }

    else if (props.size === '24') {
      window.location.href = `https://buy.stripe.com/3csaHk92j5oIc4o3cj?client_reference_id=${clientReferenceId}`;
    }
  };

  const handleDownload = async (event) => {
    event.preventDefault();

    const imageUrl = props.imageUrl;
    sendCloudwatchEvent("Download checkout button clicked with imageUrl: " + imageUrl);

    let clientReferenceId = imageUrl.split('/')[3];
    clientReferenceId = clientReferenceId.slice(0,36);

    window.location.href = `https://buy.stripe.com/dR68zcemD3gAc4oeUW?client_reference_id=${clientReferenceId}`;
  };

  if (props.callback === "canvas") {
    return (
      <Button onClick={handleCanvas} variant='solid' colorScheme='purple'>
        {props.buttonText}
      </Button>
    );
  }

  if (props.callback === "download") {
    return (
      <Button onClick={handleDownload} variant='solid' colorScheme='purple'>
        {props.buttonText}
      </Button>
    );
  }


};

export default StripeCheckoutButton;
