import React from 'react';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages';
import Gallery from './pages/gallery';
import Contact from './pages/contact';
import Download from './pages/download';
import OrderConfirmation from './pages/order-confirmation';
import DonationConfirmation from './pages/donation-confirmation';

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path='/' exact element={<Home/>} />
        <Route path='/gallery' element={<Gallery/>} />
        <Route path='/contact' element={<Contact/>} />
        <Route path='/download' element={<Download/>} />
        <Route path='/order-confirmation' element={<OrderConfirmation/>} />
        <Route path='/donation-confirmation' element={<DonationConfirmation/>} />
      </Routes>
    </Router>
  );
}

export default App;
