import React from 'react';
import { Image, keyframes, usePrefersReducedMotion } from '@chakra-ui/react';
import logo from './images/svg/logo-no-background.svg';

const spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

const styles = {
  image: {
    flex: 1,
    width: 500,
    paddingTop: 180,
    paddingBottom: 20,
    paddingLeft: 20,
    paddingRight: 20,
    resizeMode: 'contain'
  }
}

export const Logo = props => {
  const prefersReducedMotion = usePrefersReducedMotion();

  const animation = prefersReducedMotion
    ? undefined
    : `${spin} infinite 20s linear`;

  return <Image style={styles.image} src={logo} {...props} />;
};
