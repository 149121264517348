import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ChakraProvider, theme, Box, Grid, VStack, Button, Text, Stack } from '@chakra-ui/react';

function DonationConfirmation() {
  function handleClick() {
    window.location.href = 'https://www.neuralmasterpiece.com';
  }

  return (
    <ChakraProvider theme={theme}>
      <Box textAlign="center" fontSize="xl">
        <Grid p={3}>
          <VStack spacing={8}>
            <Text style={{marginTop: 125, width: '50%'}}>
              Thank you for your donation. You're helping keep this site running!
            </Text>
            <Button 
              onClick={handleClick} 
              colorScheme='purple'
            >
              Back to home page
            </Button>
          </VStack>
        </Grid>
      </Box>
    </ChakraProvider>
  );

}

export default DonationConfirmation;
