import { react, useState, useEffect, useRef } from 'react';

import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  theme,
  Input,
  Button, 
  ButtonGroup,
  Card, 
  CardHeader, 
  CardBody, 
  CardFooter,
  Image,
  Stack,
  Heading,
  Divider,
  Spinner,
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton,
  Select
} from '@chakra-ui/react';
import { ColorModeSwitcher } from '../ColorModeSwitcher';
import { Logo } from '../Logo';
import StripeCheckoutButton from '../components/StripeCheckoutButton';
import { sendCloudwatchEvent } from "../utils";

function Home() {
  const [prompt, setPrompt] = useState("");
  const [previousPrompt, setPreviousPrompt] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedSize, setSelectedSize] = useState("");

  function handleOpen () {
    setIsOpen(true);
  };

  const handleSizeChange = (event) => {
    event.preventDefault();
    setSelectedSize(event.target.value);
  };

  function handleClick() {
    sendCloudwatchEvent("Create image called with prompt: " + prompt);

    const data = { 
      prompt: prompt 
    };

    const url = 'https://a34els4yqt2mezf45zc6kseyr40pryob.lambda-url.us-east-1.on.aws/';

    const options = {
      method: 'POST',
      body: JSON.stringify(data)
    };

    setShowSpinner(true);

    fetch(url, options)
      .then(response => response.text())
      .then(data => {
        setImageUrl(data);
        setPreviousPrompt(prompt)
        setShowSpinner(false);
      })
      .catch(error => {
        setShowSpinner(false);
      });
  }

  return (
    <ChakraProvider theme={theme}>
      <Box textAlign="center" fontSize="xl" style={{marginBottom: '10%'}}>
        <Grid p={3}>
          <VStack spacing={8}>
            <Logo h="40vmin" pointerEvents="none" />
            <Text width={window.innerWidth < 500 ? "85%" : "50%" }>
              Generate art with DALLE-2
            </Text>
            <Input 
              onChange={(e) => setPrompt(e.target.value)} 
              width={window.innerWidth < 500 ? "85%" : "50%" }
              placeholder='Landscape in the style of picasso'
              onKeyPress={(event) => {
                var key = event.keyCode || event.which;
                if (key === 13) {
                  handleClick()
                }
              }}
            />
            <Button 
              onClick={handleClick} 
              colorScheme='purple'
              isDisabled={showSpinner || prompt === ""}
            >
              Create
            </Button>

            {showSpinner && <Spinner color='purple' />}

            {imageUrl !== "" && !imageUrl.includes("Error generating image") && <Card maxW='sm'>
              <CardBody>
                <Image
                  src={imageUrl}
                  borderRadius='lg'
                  onClick={() => handleOpen()}
                />
                <Stack mt='6' spacing='3'>
                  <Text>
                    {previousPrompt}
                  </Text>
                </Stack>
              </CardBody>
              <Divider style={{color: 'gray'}} />

              <CardFooter style={{justifyContent: 'center'}}>
                <div>
                  <StripeCheckoutButton callback="download" buttonText="Download" imageUrl={imageUrl}/>
                </div>
              </CardFooter>
            </Card>}

            <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} size='xl'>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>{previousPrompt}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Image src={imageUrl} style={{marginBottom: 18}}/>
                </ModalBody>
              </ModalContent>
            </Modal>

          </VStack>
        </Grid>
      </Box>
    </ChakraProvider>
  );
}

export default Home;
