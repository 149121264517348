import { react, useState, useEffect, useRef } from 'react';

import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  theme,
  Input,
  Button, 
  ButtonGroup,
  Card, 
  CardHeader, 
  CardBody, 
  CardFooter,
  Image,
  Stack,
  Heading,
  Divider,
  Spinner,
  GridItem,
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton,
  Select
} from '@chakra-ui/react';
import { ColorModeSwitcher } from '../ColorModeSwitcher';
import { Logo } from '../Logo';
import StripeCheckoutButton from '../components/StripeCheckoutButton';
import ReactPaginate from 'react-paginate';
import '../css/react-paginate.css';
import images from '../data/images';

function Gallery() {
  const [showSpinner, setShowSpinner] = useState(false);
  const [gridColumns, setGridColumns] = useState(Math.floor(window.innerWidth / 400));
  const [isOpen, setIsOpen] = useState(false);
  const [openedImage, setOpenedImage] = useState("");
  const [openedPrompt, setOpenedPrompt] = useState("");
  const [selectedSize, setSelectedSize] = useState("");

  function handleOpen (image, prompt) {
    setOpenedImage(image);
    setOpenedPrompt(prompt);
    setIsOpen(true);
  };

  const handleSizeChange = (event) => {
    event.preventDefault();
    setSelectedSize(event.target.value);
  };

  return (
    <ChakraProvider theme={theme}>
      <Box textAlign="center" fontSize="xl" style={{marginBottom: 30}}>
        <Grid p={3}>
          <VStack spacing={8} style={{marginTop: 100}}>

            {showSpinner && <Spinner color='purple' />}

            <Grid templateColumns={`repeat(${gridColumns}, 1fr)`} gap={6}>
              {images.map((image, index) => (
                <GridItem w='100%'>
                  <Card maxW='sm' style={{ height: "100%" }}>
                    <CardBody>
                      <Image
                        src={image.src}
                        borderRadius='lg'
                        onClick={() => handleOpen(image.src, image.prompt)}
                      />
                      <Stack mt='6' spacing='3'>
                        <Text>
                          {image.prompt}
                        </Text>
                      </Stack>
                    </CardBody>
                    <Divider style={{color: 'gray'}} />

                    <CardFooter style={{justifyContent: 'center'}}>
                      <div>
                        <StripeCheckoutButton callback="download" buttonText="Download" imageUrl={image.src}/>
                      </div>
                    </CardFooter>
                  </Card>

                </GridItem>
              ))}

            </Grid>

            <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} size='xl'>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>{openedPrompt}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Image src={openedImage} style={{marginBottom: 18}}/>
                </ModalBody>
              </ModalContent>
            </Modal>

          </VStack>
        </Grid>
      </Box>
    </ChakraProvider>
  );
}

export default Gallery;
