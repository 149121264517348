import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ChakraProvider, theme, Box, Grid, VStack, Button, 
  Text, useToast, Spinner, Card, CardBody, Image, Stack } from '@chakra-ui/react';

function OrderConfirmation() {
  const [orderId, setOrderId] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [prompt, setPrompt] = useState("");
  const [orderDate, setOrderDate] = useState(new Date(Date.now()).toLocaleString());

  const location = useLocation();
  const toast = useToast();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const sessionId = query.get('checkout_session_id');

    if (!sessionId) {
      console.error('Missing checkout_session_id query parameter');
      return;
    }

    const data = { 
      sessionId: sessionId 
    };

    const url = 'https://tqgvs5wflng4g7bjfbujxef7au0yqtnt.lambda-url.us-east-1.on.aws/';

    const options = {
      method: 'POST',
      body: JSON.stringify(data)
    };

    fetch(url, options)
      .then((res) => res.json())
      .then((data) => {
        setOrderId(data.client_reference_id);
        setImageUrl(data.image_url);
        setPrompt(data.prompt);
      })
      .catch((error) => {
        console.error('Error fetching checkout session:', error);
      });
  }, [location.search]);

  function handleClick() {
    window.location.href = 'https://www.neuralmasterpiece.com';
  }

  function handleCopyToClipboard() {
    navigator.clipboard.writeText(orderId);
    toast({
      title: 'Order ID to clipboard',
      status: 'success',
      duration: 9000,
      isClosable: true,
    })
  }

  return (
    <ChakraProvider theme={theme}>
      <Box textAlign="center" fontSize="xl">
        <Grid p={3}>
          <VStack spacing={8}>
            <Text style={{marginTop: 125, width: '50%'}}>
              Thank you for your order. Expect up to 15 business days for a shipment from Shutterfly to arrive. Please keep this Order ID for your records:
            </Text>

            {orderId === "" && <Spinner color='purple' />}

            {orderId !== "" && <Text>
              {orderId}
            </Text>}

            {orderId !== "" && 
            <Button colorScheme='purple' onClick={handleCopyToClipboard}>
              Copy Order ID
            </Button>}

            {imageUrl !== "" && <Card maxW='sm' style={{marginBottom: 100}}>
              <CardBody>
                <Image
                  src={imageUrl}
                  borderRadius='lg'
                />
                <Stack mt='6' spacing='3'>
                  <Text>
                    {prompt}
                  </Text>
                </Stack>
              </CardBody>
            </Card>}

          </VStack>
        </Grid>
      </Box>
    </ChakraProvider>
  );

}

export default OrderConfirmation;
