function sendCloudwatchEvent(cloudwatch_event) {
	const data = {
      cloudwatch_event: cloudwatch_event 
    };

    const url = 'https://ufepm6iv7raydycov3bsoowzdy0rcbqx.lambda-url.us-east-1.on.aws/';

    const options = {
      method: 'POST',
      body: JSON.stringify(data)
    };

    fetch(url, options);
}

export {
	sendCloudwatchEvent
}