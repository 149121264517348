import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ChakraProvider, theme, Box, Grid, VStack, Button, 
  Text, useToast, Spinner, CardBody, Card, Image, Stack,
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton } from '@chakra-ui/react';

function Download() {
  const [redirectUrl, setRedirectUrl] = useState("");
  const [prompt, setPrompt] = useState("");
  const location = useLocation();
  const toast = useToast();
  const [isOpen, setIsOpen] = useState(false);

  function handleOpen () {
    setIsOpen(true);
  };

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const sessionId = query.get('checkout_session_id');

    if (!sessionId) {
      console.error('Missing checkout_session_id query parameter');
      return;
    }

    const data = { 
      sessionId: sessionId 
    };

    const url = 'https://tqgvs5wflng4g7bjfbujxef7au0yqtnt.lambda-url.us-east-1.on.aws/';

    const options = {
      method: 'POST',
      body: JSON.stringify(data)
    };

    fetch(url, options)
      .then((res) => res.json())
      .then((data) => {
        setRedirectUrl(data.image_url);
        setPrompt(data.prompt);
        window.location.href = data.image_url;
      })
      .catch((error) => {
        console.error('Error fetching checkout session:', error);
      });
  }, [location.search]);

  function handleClick() {
    window.location.href = 'https://www.neuralmasterpiece.com';
  }

  function handleCopyToClipboard() {
    navigator.clipboard.writeText(redirectUrl);
    toast({
      title: 'Link copied to clipboard',
      status: 'success',
      duration: 9000,
      isClosable: true,
    })
  }

  return (
    <ChakraProvider theme={theme}>
      <Box textAlign="center" fontSize="xl">
        <Grid p={3}>
          <VStack spacing={8}>
            <Text style={{marginTop: 125, width: '50%'}}>
              Thank you for your download
            </Text>

            {redirectUrl === "" && <Spinner color='purple' />}

            {redirectUrl !== "" && window.innerWidth > 500 && <Card maxW='sm'>
              <CardBody>
                <Image
                  src={redirectUrl}
                  borderRadius='lg'
                  onClick={() => handleOpen()}
                />
                <Stack mt='6' spacing='3'>
                  <Text>
                    {prompt}
                  </Text>
                </Stack>
              </CardBody>
            </Card>}

            {redirectUrl !== "" && 
            <Button colorScheme='purple' style={{marginBottom: 100}} onClick={handleCopyToClipboard}>
              Copy image link
            </Button>}

            <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} size='xl'>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>{prompt}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Image src={redirectUrl} style={{marginBottom: 18}}/>
                </ModalBody>
              </ModalContent>
            </Modal>

          </VStack>
        </Grid>
      </Box>
    </ChakraProvider>
  );

}

export default Download;
