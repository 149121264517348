import { react } from 'react';

import {
  ChakraProvider,
  Box,
  Text,
  VStack,
  Grid,
  theme,
} from '@chakra-ui/react';
import { ColorModeSwitcher } from '../ColorModeSwitcher';

function Contact() {
  return (
    <ChakraProvider theme={theme}>
      <Box textAlign="center" fontSize="xl">
        <Grid p={3}>
          <VStack spacing={8}>

            <Text style={{marginTop: 125}}>Contact us at: support@sportsdataproducts.com</Text>

          </VStack>
        </Grid>
      </Box>
    </ChakraProvider>
  );
}

export default Contact;
