import React from 'react';
import {
  Nav,
  NavLink,
  NavLinkDisabled,
  Bars,
  NavMenu,
  NavBtn,
  NavBtnLink,
} from './NavbarElements';
import { sendCloudwatchEvent } from "../../../src/utils";

const Navbar = () => {

  const handleClick = () => {
    sendCloudwatchEvent("Donate button clicked");
    window.location.href = 'https://buy.stripe.com/eVa5n01zRdVe3xS14d';
  }

  return (
    <>
      <Nav>
        <NavMenu>
          <NavLink to='/' activeStyle>
            Create
          </NavLink>
          <NavLink to='/gallery' activeStyle>
            Gallery
          </NavLink>
          <NavLink to='/contact' activeStyle>
            Contact
          </NavLink>
          <NavLinkDisabled onClick={()=> handleClick()}>
            Donate
          </NavLinkDisabled>
        </NavMenu>
      </Nav>
    </>
  );
};
  
export default Navbar;