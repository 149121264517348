const images = [
    {
        "src": "https://neuralmasterpiece-images-watermarked.s3.amazonaws.com/164e819e-0c6b-4c20-9086-034451891c38-majestic-painting-of-colonial-era-new-england.jpg",
        "prompt": "Majestic painting of colonial era new england"
    },
    {
        "src": "https://neuralmasterpiece-images-watermarked.s3.amazonaws.com/884a97e7-8f32-4557-ac06-49e2e961bdd5-rio-de-janeiro-at-sunset-in-the-style-of-candido-portinari.jpg",
        "prompt": "Rio de janeiro at sunset in the style of candido portinari"
    },
    {
        "src": "https://neuralmasterpiece-images-watermarked.s3.amazonaws.com/9f858320-0071-42fa-bd2f-903e8631f778-abstract-colorful-meadow.jpg",
        "prompt": "Abstract colorful meadow"
    },
    {
        "src": "https://neuralmasterpiece-images-watermarked.s3.amazonaws.com/437f4f42-c370-4a39-b97a-eb5d6a2d57d0-internal-conflict-of-the-violent-mind%2C-cubism-.jpg",
        "prompt": "Internal conflict of the violent mind, cubism"
    },
    {
        "src": "https://neuralmasterpiece-images-watermarked.s3.amazonaws.com/bde151df-e18c-4778-b7da-3811133e68e2-beautiful-painting-of-a-small-16th-century-town%2C-sunrise-over-the-ocean-at-cinque-terra-italy%2C-several-small-farms-and-a-vineyard%2C-livestock.jpg",
        "prompt": "Beautiful painting of a small 16th century town, sunrise over the ocean at cinque terra italy, several small farms and a vineyard, livestock"
    },
    {
        "src": "https://neuralmasterpiece-images-watermarked.s3.amazonaws.com/fa532d6f-764d-489e-b040-5971ee779152-west-seattle-summertime-festival-in-pop-art-style-painting.jpg",
        "prompt": "West Seattle summertime festival in pop art style painting"
    },
    {
        "src": "https://neuralmasterpiece-images-watermarked.s3.amazonaws.com/3559ba26-5e32-44bb-8b27-f39cb726a9ef-majestic-oil-portrait-of-a-pit-bull-in-marine-general-uniform.jpg",
        "prompt": "Majestic oil portrait of a pit bull in marine general uniform"
    },
    {
        "src": "https://neuralmasterpiece-images-watermarked.s3.amazonaws.com/2c9eae48-deb0-4b4d-bea7-5f04fdb11557-civil-war-era-photo-of-man-drinking-a-beer-sitting-on-a-log.jpg",
        "prompt": "Civil war era photo of man drinking a beer sitting on a log"
    },
    {
        "src": "https://neuralmasterpiece-images-watermarked.s3.amazonaws.com/8d2e0982-4fcf-432e-b929-1478d31ce377-landscape-in-the-style-of-%22mario64%22.jpg",
        "prompt": "Landscape in the style of mario64"
    },
    {
        "src": "https://neuralmasterpiece-images-watermarked.s3.amazonaws.com/bc7a6f25-6cb0-4426-839e-a80907219e25-epic-sunset-over-downtown-ventura%2C-ca-in-the-style-of-baywatch.jpg",
        "prompt": "Epic sunset over downtown ventura, ca in the style of baywatch"
    },
    {
        "src": "https://neuralmasterpiece-images-watermarked.s3.amazonaws.com/8f565983-5774-49e4-bdd7-3db2cff5b5aa-oil-painting-of-a-civil-war-general-in-the-style-of-andy-warhol.jpg",
        "prompt": "Oil painting of a civil war general in the style of andy warhol"
    },
    {
        "src": "https://neuralmasterpiece-images-watermarked.s3.amazonaws.com/14099e45-7335-41d7-8f65-b47bdb5c9f19-beautiful-daybreak-5th-century-pastoral-scene%2C-2-small-huts%2C-2-small-farms%2C-8-livestock%2C-dense-forest%2C-cliffside-.jpg",
        "prompt": "Beautiful daybreak 5th century pastoral scene, 2 small huts, 2 small farms, 8 livestock, dense forest, cliffside"
    },
    {
        "src": "https://neuralmasterpiece-images-watermarked.s3.amazonaws.com/9bbc37d7-225e-4de8-b3ac-aeafd3efe28a-cherry-blossoms-blooming-at-university-of-washington.jpg",
        "prompt": "Cherry blossoms blooming at university of washington"
    },
    {
        "src": "https://neuralmasterpiece-images-watermarked.s3.amazonaws.com/3f1dda16-79b2-4017-a4b7-b84363333343-landscape-in-the-style-of-picasso.jpg",
        "prompt": "Landscape in the style of picasso"
    },
    {
        "src": "https://neuralmasterpiece-images-watermarked.s3.amazonaws.com/99c9a437-6e76-441e-8eea-e3c63d845e2f-keith-haring-painting-of-seattle-during-sea-fair-celebration%2C-blue-angel-fighters%2C-hydroplane-boat-races%2C-festival.jpg",
        "prompt": "Keith haring painting of seattle during sea fair celebration, blue angel fighters, hydroplane boat races, festival"
    },
    {
        "src": "https://neuralmasterpiece-images-watermarked.s3.amazonaws.com/9e1b0581-0528-43ff-93d1-91fc2b3ba035-viking-in-world-of-warcaft-.jpg",
        "prompt": "Viking in world of warcaft"
    },
    {
        "src": "https://neuralmasterpiece-images-watermarked.s3.amazonaws.com/11531e79-a1d9-470a-9f0a-aa70ad930a87-mountains-in-the-style-of-monet.jpg",
        "prompt": "Mountains in the style of monet"
    },
    {
        "src": "https://neuralmasterpiece-images-watermarked.s3.amazonaws.com/bc92659a-1793-427f-a231-4031c7cd6ad6-majestic-painting%2C-aerial-perspective-of-several-farms-along-the-mississippi-river-delta-at-daybreak%2C-1880%27s%2C-bright%2C-natural%2C-pastoral-tones%2C-early-spring.jpg",
        "prompt": "Majestic painting, aerial perspective of several farms along the Mississippi River Delta at daybreak, 1880's, bright, natural, pastoral tones, early Spring"
    },
    {
        "src": "https://neuralmasterpiece-images-watermarked.s3.amazonaws.com/f2d2ba03-34fe-450c-97e8-a70e0f1b4316-mayan-temple-in-a-jungle%2C-early-foggy-morning%2C-sun-breaking-through-the-canopy.jpg",
        "prompt": "Mayan temple in a jungle, early foggy morning, sun breaking through the canopy"
    },
    {
        "src": "https://neuralmasterpiece-images-watermarked.s3.amazonaws.com/11a503a2-e4bd-4f8b-a53b-167472800b9a-japanese-style-painting.jpg",
        "prompt": "Japanese style painting"
    },
    {
        "src": "https://neuralmasterpiece-images-watermarked.s3.amazonaws.com/1e500a8e-ed8e-483c-9cc4-07af88e52598-pop-art-style-painting-of-kurt-cobain-singing-and-playing-guitar-on-stage-at-a-concert%2C-perspective-from-the-back-of-the-concert-venue.jpg",
        "prompt": "Pop art style painting of kurt cobain singing and playing guitar on stage at a concert, perspective from the back of the concert venue"
    },
    {
        "src": "https://neuralmasterpiece-images-watermarked.s3.amazonaws.com/c3be4c55-d6d0-4bc7-839d-894910b33166-man-checking-his-iphone-while-on-a-data%2C-in-the-style-of-van-gogh.jpg",
        "prompt": "Man checking his iphone, in the style of van gogh"
    },
    {
        "src": "https://neuralmasterpiece-images-watermarked.s3.amazonaws.com/928ee289-47bf-4530-94bd-68f93995bc0d-beethoven-composing-music-at-the-piano%2C-depressing%2C-dark-tones%2C-impressionism%2C-anger%2C-storms-outside-through-the-window%2C-nighttime.jpg",
        "prompt": "Beethoven composing music at the piano, depressing, dark tones, impressionism, anger, storms outside through the window, nighttime"
    },
    {
        "src": "https://neuralmasterpiece-images-watermarked.s3.amazonaws.com/a9d79b50-d257-4785-9623-93f382b5d220-spring-begins%2C-in-the-style-of-jackson-pollock%2C-minimalist.jpg",
        "prompt": "Spring begins, in the style of jackson pollock, minimalist"
    },
    {
        "src": "https://neuralmasterpiece-images-watermarked.s3.amazonaws.com/c39a5c25-0caf-418f-a2c4-8d6767d1515e-impressionist-painting-of-lombard-street%2C-curvy-road%2C-san-francisco%2C-early-spring%2C-tulips-and-hydrangeas%2C-sunny%2C-perspective-from-the-bottom-of-the-hill.jpg",
        "prompt": "Impressionist painting of lombard street, curvy road, san francisco, early spring, tulips and hydrangeas, sunny, perspective from the bottom of the hill"
    },
    {
        "src": "https://neuralmasterpiece-images-watermarked.s3.amazonaws.com/8bc7f33e-e15e-41f1-b0d1-660aa6cef892-tristan-da-cunha-island-at-dawn-during-the-summer%2C-aerial-view%2C-painting-in-the-style-of-lynne-drexler.jpg",
        "prompt": "Tristan da cunha island at dawn during the summer, aerial view, painting in the style of lynne drexler"
    },
    {
        "src": "https://neuralmasterpiece-images-watermarked.s3.amazonaws.com/a84cf9f2-4c84-484c-957a-6d86ff678526-opposites-attract%2C-painting-of-a-dog-and-a-cat.jpg",
        "prompt": "Opposites attract, painting of a dog and a cat"
    },
    {
        "src": "https://neuralmasterpiece-images-watermarked.s3.amazonaws.com/e6a0ceae-d91f-48ed-af82-c5d27125fac4-neoclassical-painting-of-tiny-vietnamese-coastal-village%2C-cliffsides%2C-several-livestock%2C-dense-jungle%2C-sunset-over-ocean.jpg",
        "prompt": "Neoclassical painting of tiny vietnamese coastal village, cliffsides, several livestock, dense jungle, sunset over ocean"
    },
    {
        "src": "https://neuralmasterpiece-images-watermarked.s3.amazonaws.com/419d0232-a7a3-4949-8d1f-d9e7ad47b143-fantastic-wonderland-with-lollipops-and-rainbows.jpg",
        "prompt": "Fantastic wonderland with lollipops and rainbows"
    },
    {
        "src": "https://neuralmasterpiece-images-watermarked.s3.amazonaws.com/393575b2-1230-45d0-ac53-1d3ecdaafc79-winter-at-a-log-cabin-in-the-woods-neoclassical-style.jpg",
        "prompt": "Winter at a log cabin in the woods neoclassical style"
    },
    {
        "src": "https://neuralmasterpiece-images-watermarked.s3.amazonaws.com/f5592223-3149-49af-a21e-67aa58157729-pop-art-style-painting-of-world-series-celebration-parade-in-seattle%2C-mariners%2C-space-needle%2C-gigantic-crowd-of-people%2C-sunny-early-november-day.jpg",
        "prompt": "Pop art style painting of world series celebration parade in seattle, mariners, space needle, gigantic crowd of people, sunny early november day"
    },
    {
        "src": "https://neuralmasterpiece-images-watermarked.s3.amazonaws.com/e2caa378-7e54-4996-8596-0a17702f9ea9-prehistoric-painting-inside-a-cave%2C-t-rex-chases-a-stegosaurus%2C-egyptian-hieroglyphics%2C-cave-art%2C-primitive%2C-.jpg",
        "prompt": "Prehistoric painting inside a cave, t rex chases a stegosaurus, egyptian hieroglyphics, cave art, primitive,"
    },
    {
        "src": "https://neuralmasterpiece-images-watermarked.s3.amazonaws.com/070af230-9655-4f8f-b683-24c86e1245e9-painting-in-the-style-of-a-1970s-tourism-advertisement%2C-dubai-beach-at-sunset.jpg",
        "prompt": "Painting in the style of a 1970s tourism advertisement, dubai beach at sunset"
    },
    {
        "src": "https://neuralmasterpiece-images-watermarked.s3.amazonaws.com/6bdd65c7-08ff-41f6-b987-641a746e81d4-1890s-style-painting%2C-two-pigs-walking-on-their-hind-legs-on-a-saturday-night-in-soho-new-york%2C-early-spring%2C-dressed-lavishly-in-gold-and-tuxedos.jpg",
        "prompt": "1890s style painting, two pigs walking on their hind legs on a saturday night in soho new york, early spring, dressed lavishly in gold and tuxedos"
    },
    {
        "src": "https://neuralmasterpiece-images-watermarked.s3.amazonaws.com/6e93d620-0ffb-4182-a831-12bee9ada4a9-small-summer-cottage-on-top-of-floating-cliff%2C-fantasy-theme%2C-digital-art.jpg",
        "prompt": "Small summer cottage on top of floating cliff, fantasy theme, digital art"
    },
    {
        "src": "https://neuralmasterpiece-images-watermarked.s3.amazonaws.com/24512d0e-e69f-494a-bec1-71f287a3fdf3-the-end-of-a-pandemic-related-public-health-emergency%2C-red-cross-rescue-boat%2C-painting-in-the-style-of-rembrandt%27s-the-storm-on-the-sea-of-galilee.jpg",
        "prompt": "The end of a pandemic related public health emergency, red cross rescue boat, painting in the style of rembrandt's the storm on the sea of galilee"
    },
    {
        "src": "https://neuralmasterpiece-images-watermarked.s3.amazonaws.com/38719fe4-d1ad-4872-8f0a-8c2ea916570d-old-illustration-in-the-style-of-concept-art-about-a-tulip%27s-lifecycle-from-germination-to-flower-bloom.jpg",
        "prompt": "Old illustration in the style of concept art about a tulip's lifecycle from germination to flower bloom"
    },
    {
        "src": "https://neuralmasterpiece-images-watermarked.s3.amazonaws.com/65719978-ed72-4306-9aef-40468191e2e4-old-illustration-in-the-style-of-concept-art%2C-view-from-the-top-of-a-oktoberfest-roller-coaster-during-festival%2C-beer-halls-and-munich-skyline-in-distance%2C-jolly-theme.jpg",
        "prompt": "Old illustration in the style of concept art, view from the top of a oktoberfest roller coaster during festival, beer halls and munich skyline in distance, jolly theme"
    },
    {
        "src": "https://neuralmasterpiece-images-watermarked.s3.amazonaws.com/ec9d2276-2b2b-4fca-951f-c395961acb11-rothenburg-ob-der-tauber%2C-fantasy-theme%2C-digital-art.jpg",
        "prompt": "Rothenburg ob der tauber, fantasy theme, digital art"
    },
    {
        "src": "https://neuralmasterpiece-images-watermarked.s3.amazonaws.com/b21b78e3-bf52-4c42-a2d9-999cb85e33e8-majestic-cliffside-waterfall%2C-epic-painting-of-ancient-hawaii.jpg",
        "prompt": "Majestic cliffside waterfall, epic painting of ancient hawaii"
    },
    {
        "src": "https://neuralmasterpiece-images-watermarked.s3.amazonaws.com/09d20658-ae81-452f-95d3-8ea1c10ef5c0-rothenburg-ob-der-tauber%2C-fantasy-theme%2C-digital-art.jpg",
        "prompt": "Rothenburg ob der tauber, fantasy theme, digital art"
    },
    {
        "src": "https://neuralmasterpiece-images-watermarked.s3.amazonaws.com/cc8809b3-bab9-435e-92a1-e81134c200d3-old-world-map-in-style-of-15th-century-navigator.jpg",
        "prompt": "Old world map in style of 15th century navigator"
    },
    {
        "src": "https://neuralmasterpiece-images-watermarked.s3.amazonaws.com/3f35febb-2e16-4678-b083-d0ea2850127f-a-frightening-monster-chases-its-prey-in-a-misty-forest%2C-horror-movie-movie-poster.jpg",
        "prompt": "A frightening monster chases its prey in a misty forest, horror movie movie poster"
    }
];

export default images;

